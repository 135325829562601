// src/redux/actions/seatAction.js

import instance from '../../api'; // Importa l'istanza di axios
import { BOOK_SEAT_SUCCESS } from './types'; // Importa il tipo di azione
import { FETCH_SEATS_SUCCESS } from './types'; // Importa il tipo di azione
import { SEND_EMAIL_SUCCESS } from './types'; // Importa il tipo di azione
import { SEND_EMAIL_FAILURE } from './types'; // Importa il tipo di azione

// Azione per prenotare un posto
export const bookSeat = (row, number, name, surname, email, telefono) => async dispatch => {
  try {
    console.log("Entro in bookSeat con row: ", row, "number: ", number, "e chiamo il backend per prenotare il posto")
    // Effettua la richiesta al backend per prenotare il posto
    const res = await instance.post('/book-seat', { row, number, name, surname, email, telefono});
    // Aggiorna lo stato dopo aver prenotato con successo
    dispatch({
      type: BOOK_SEAT_SUCCESS,
      payload: { row, number, name, surname, email, telefono},
    });
    // Restituisci il posto prenotato
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('Unable to book seat');
  }
};

// Azione per ottenere i posti dal backend
export const fetchSeats = (date) => async dispatch => {
  try {
    console.log("Recupero i posti")
    // Effettua la richiesta al backend per ottenere i posti e quelli nel carrello
    const res = await instance.get(`/seats?date=${date}`);

    // Aggiorna lo stato con i posti ottenuti dalla collezione Seats e Carrello
    dispatch({
      type: FETCH_SEATS_SUCCESS,
      payload: {
        seats: res.data.seats,
        cartSeats: res.data.cartSeats,
        data: res.data
      },
    });
  } catch (error) {
    console.error('Errore durante il recupero dei posti:', error);
    throw new Error('Unable to fetch seats');
  }
};

// Azione per inviare un'email
export const sendEmail = (to, seats, name, surname, telefono) => async dispatch => {
  try {
    // Effettua una richiesta POST per inviare un'email
    const response = await instance.post('/send-email', { to, seats, name, surname, telefono});

    // Gestisci la risposta, se necessario
    console.log('Risposta dal server:', response.data);
    dispatch({
      type: SEND_EMAIL_SUCCESS,
      payload: response.data, // Puoi passare eventuali dati dal server come payload
    });
    return response.data;
  } catch (error) {
    // Gestisci gli errori, se necessario
    console.error('Errore durante l\'invio dell\'email:', error);
    dispatch({
      type: SEND_EMAIL_FAILURE,
      payload: error.message, // Puoi passare eventuali messaggi di errore come payload
    });
    throw new Error('Unable to send email');
  }
}

export const updateSeat = (row, number, sessionId, lockedUntil) => async dispatch => {
  try {
    console.log("Entro in updateSeat con row: ", row, "number: ", number, "sessionId: ", sessionId, "locked: ", lockedUntil, "\ne chiamo il backend per aggiornare il posto")
    // Effettua la richiesta al backend per aggiornare il posto
    const res = await instance.post('/update-seat', { row, number, sessionId, lockedUntil });
    // Aggiorna lo stato dopo aver aggiornato con successo
    dispatch({
      type: BOOK_SEAT_SUCCESS,
      payload: { row, number, sessionId, lockedUntil},
    });
    // Restituisci il posto aggiornato
    return res.data;
  } catch (error) {
    console.error(error);
    throw new Error('Unable to update seat');
  }
}

export const checkAllSeatsAvailable = (seats) => async dispatch => {
  try {

    // Effettua la richiesta al backend per controllare lo stato dei posti selezionati
    const res = await instance.post('/check-selected-seats', { seats });
    // Ritorna true se tutti i posti selezionati sono liberi, altrimenti ritorna false
    console.log(res.data.every(seat => seat.stato === 'Disponibile'))
    // Aggiorna lo stato dopo aver aggiornato con successo
    dispatch({
      type: BOOK_SEAT_SUCCESS,
      payload: { seats },
    });
    // Restituisci il posto aggiornato
    return res.data.every(seat => seat.stato === 'Disponibile');
  } catch (error) {
    console.error('Errore durante il controllo dei posti selezionati:', error);
    throw new Error('Unable to check selected seats availability');
  }
}

